import React from 'react'

/* eslint-disable max-len */
const PrintIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 500 500"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M352.2,327.1c0-7.4-5.3-13.5-12.2-14.8v-0.2h-1c-0.6-0.1-1.2-0.1-1.9-0.1s-1.2,0.1-1.9,0.1h-168c-0.6-0.1-1.2-0.1-1.9-0.1c-8.4,0-15.1,6.8-15.1,15.1s6.8,15.1,15.1,15.1c0.6,0,1.2-0.1,1.9-0.1h168c0.6,0.1,1.2,0.1,1.9,0.1s1.2-0.1,1.9-0.1h1V342C346.9,340.6,352.2,334.5,352.2,327.1z" />
    <path d="M352.2,381c0-7.4-5.3-13.5-12.2-14.8V366h-1c-0.6-0.1-1.2-0.1-1.9-0.1s-1.2,0.1-1.9,0.1h-168c-0.6-0.1-1.2-0.1-1.9-0.1c-8.4,0-15.1,6.8-15.1,15.1s6.8,15.1,15.1,15.1c0.6,0,1.2-0.1,1.9-0.1h168c0.6,0.1,1.2,0.1,1.9,0.1s1.2-0.1,1.9-0.1h1v-0.2C346.9,394.5,352.2,388.4,352.2,381z" />
    <path d="M437.7,135.7h-29.7V31.4c0-9.4-7.6-17-17-17H109.1c-9.4,0-17,7.6-17,17v104.3H64.7c-27.5,0-50,22.5-50,50v152c0,27.5,22.5,50,50,50h27.4v81c0,9.4,7.6,17,17,17h281.9c9.4,0,17-7.6,17-17v-81h29.7c27.5,0,50-22.5,50-50v-152C487.7,158.2,465.2,135.7,437.7,135.7z M122.1,44.4h255.9v91.3H122.1V44.4z M380.2,455.1h-2.2v0.5H122.1v-68h0.1v-108h258V455.1zM430.8,208.6c-9.5,0-17.2-7.7-17.2-17.2s7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2S440.4,208.6,430.8,208.6z" />
  </svg>
)

export default PrintIcon
